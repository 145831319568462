
@font-face {
    font-family: "Pretendard-R";
    src: url("./font/Pretendard-Regular.woff2") format("woff2");
    font-display: auto;
  
  }
  
  
  @font-face {
    font-family: "Pretendard-B";
    src: url("./font/Pretendard-Bold.woff2") format("woff2");
    font-display: auto;
  
  }

  .k-tilelayout-item-header.k-card-header{
    font-family: "Pretendard-R";
    background-color: #34568B;
    color:#F1F2F5;
    font-weight: bold;
  }

  .body{
    font-family: "Pretendard-R";

  }

  .k-tilelayout-item-header.k-card-header{
    padding: 6px 12px!important;
  }
  .k-tilelayout-item.k-card{
  border-radius: 0px!important;
  }

  .richimag img{
    width: 100%;
    object-fit: contain; 
    max-height: 200px;
  }

  .progress .k-tilelayout-item-header.k-card-header{
    font-size: 1.2rem;
  }

  .hovertext:hover {
    color:  #34568B;
    font-weight: bolder;
  }

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #EADEDB;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ptable .k-grid-header{
  padding-right:10px;
}

.ptable .k-cell-inner > .k-link {
  justify-content: center;
}

.ptable .k-grid-header col:nth-of-type(1){
  width: 200px
}
.ptable .k-grid-table col:nth-of-type(1){
  width: 200px
}

.ptable .k-grid-header col:nth-of-type(2){
  width: 12%;
}
.ptable .k-grid-table col:nth-of-type(2){
  width: 12%;

}

.ptable .k-grid-header col:nth-of-type(3){
  width: 12%
}
.ptable .k-grid-table col:nth-of-type(3){
  width: 12%
}

.ptable .k-grid-header col:nth-of-type(4){
  width: 12%
}
.ptable .k-grid-table col:nth-of-type(4){
  width: 12%
}

.ptable .k-grid-header col:nth-of-type(5){
  width: 12%
}
.ptable .k-grid-table col:nth-of-type(5){
  width: 12%
}

.ptable .k-grid-header col:nth-of-type(6){
  width: 12%
}
.ptable .k-grid-table col:nth-of-type(6){
  width: 12%
}

.ptable .k-grid-header col:nth-of-type(7){
  width: 12%
}
.ptable .k-grid-table col:nth-of-type(7){
  width: 12%
}

.ptable .k-grid-header col:nth-of-type(8){
  width: 12%
}
.ptable .k-grid-table col:nth-of-type(8){
  width: 12%
}
 



.issuetable .k-grid-header col:nth-of-type(1){
  width: 30%
}
.issuetable .k-grid-table col:nth-of-type(1){
  width: 30%
}
.issuetable .k-grid-header col:nth-of-type(2){
width: 70%
}
.issuetable .k-grid-table col:nth-of-type(2){
width: 70%}

.richimag2 img{
  width: 100%;
  object-fit: scale-down;
}

.MuiTypography-body2 {
  font-size: 0.8rem!important;
}

.k-input-md .k-input-inner, .k-picker-md .k-input-inner {
  padding: 2px 4px;
}

.k-window-actions {
  margin: 0!important;
}

.k-chart {
  border: none;
}

.k-picker, .k-input{
  border-color: lightgray;
}


tbody > tr:last-child > td {
  border-bottom: 0;
}



.atable2 .k-grid-header col:nth-of-type(1){
  width: 500px
}
.atable2 .k-grid-table col:nth-of-type(1){
  width: 500px
}
.atable2 .k-grid-footer col:nth-of-type(1){
  width: 500px
}


.atable2 .k-grid-header col:nth-of-type(2){
  width:  20%

}
.atable2 .k-grid-table col:nth-of-type(2){
  width: 20%
}
.atable2 .k-grid-footer col:nth-of-type(2){
  width: 20%

}

.atable2 .k-grid-header col:nth-of-type(3){
  width:  10%

}
.atable2 .k-grid-table col:nth-of-type(3){
  width: 10%
}
.atable2 .k-grid-footer col:nth-of-type(3){
  width: 10%

}

.atable2 .k-grid-header col:nth-of-type(4){
  width:  10%

}
.atable2 .k-grid-table col:nth-of-type(4){
  width: 10%
}
.atable2 .k-grid-footer col:nth-of-type(4){
  width: 10%

}

.atable  .k-column-title, .atable .k-table-td, .atable .k-grid-footer td{
  font-size: 11px!important;
}

.atable .k-grid-header col:nth-of-type(1){
  width: 50px
}
.atable .k-grid-table col:nth-of-type(1){
  width: 50px
}
.atable .k-grid-footer col:nth-of-type(1){
  width: 50px}


.atable .k-grid-header col:nth-of-type(2){
  width:  20%

}
.atable .k-grid-table col:nth-of-type(2){
  width: 20%
}
.atable .k-grid-footer col:nth-of-type(2){
  width: 20%

}

.atable .k-grid-header col:nth-of-type(3){
  width:  20%

}
.atable .k-grid-table col:nth-of-type(3){
  width: 20%
}
.atable .k-grid-footer col:nth-of-type(3){
  width: 20%

}

.atable .k-grid-header col:nth-of-type(4){
  width:  20%

}
.atable .k-grid-table col:nth-of-type(4){
  width: 20%
}
.atable .k-grid-footer col:nth-of-type(4){
  width: 20%

}

.atable .k-grid-header col:nth-of-type(5){
  width:  20%

}
.atable .k-grid-table col:nth-of-type(5){
  width: 20%
}
.atable .k-grid-footer col:nth-of-type(5){
  width: 20%

}

.atable table th {
  justify-content: center;
}



.atable td {
  text-align: center;
}





.atable3 .k-grid-header col:nth-of-type(1){
  width: 450px
}
.atable3 .k-grid-table col:nth-of-type(1){
  width: 450px
}
.atable3 .k-grid-footer col:nth-of-type(1){
  width: 450px
}


.atable3 .k-grid-header col:nth-of-type(2){
  width:  20%

}
.atable3 .k-grid-table col:nth-of-type(2){
  width: 20%
}
.atable3 .k-grid-footer col:nth-of-type(2){
  width: 20%

}

.atable3 .k-grid-header col:nth-of-type(3){
  width:  10%

}
.atable3 .k-grid-table col:nth-of-type(3){
  width: 10%
}
.atable3 .k-grid-footer col:nth-of-type(3){
  width: 10%

}


.atable3 .k-grid-header col:nth-of-type(5){
  width:  15%

}
.atable3 .k-grid-table col:nth-of-type(5){
  width: 15%
}
.atable3 .k-grid-footer col:nth-of-type(5){
  width: 15%

}

.atable3 .k-grid-header col:nth-of-type(4){
  width:  15%

}
.atable3 .k-grid-table col:nth-of-type(4){
  width: 15%
}
.atable3 .k-grid-footer col:nth-of-type(4){
  width: 15%

}

.qcost .k-grid-table{
  table-layout: fixed;
}

.qcost .k-grouping-row td{
  border: none!important;
}

.qcost .k-table-td {
  border-width: 1px 1px 1px 1px!important;
}

.qcost .k-master-row .k-grid-content-sticky {
  border-color:rgba(0, 0, 0, 0.08)!important;
}

.qcost .k-table-th {
  border-color:rgba(0, 0, 0, 0.08)!important;
}

.qcost .k-grid-footer-sticky{
  border-color:rgba(0, 0, 0, 0.08)!important;
}